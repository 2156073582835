






























































































































































































































































































































































































































































































































































#two-way-modal.show {
  display: flex !important;
  align-items: center;
  justify-content: center;

  .modal-body {
    background: #ff914b url('~~~@assets/images/loops.png') no-repeat;
    background-size: 22%;
    background-position: center left;
    background-position-x: 40px;
  }

  .modal-tag {
    text-align: center;
    margin-bottom: 15px;

    span {
      color: #1e6c32;
      background: #d0ffdc;
      font-weight: 700;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .modal-detail {
    margin-left: 250px;
    background: #FFF;
    padding: 20px;
    padding-left: 80px;
    position: relative;
    overflow: hidden;

    &:before {
      content: " ";
      height: 118%;
      width: 261px;
      display: block;
      position: absolute;
      top: -28px;
      left: -193px;
      background: #ff914b;
      border-radius: 0 50% 50% 0;
    }
  }

  ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 30px;

    li {
      color: #0e0e0e;
      margin-bottom: 10px;
      font-weight: 700;
      display: flex;
      align-items: center;

      i {
        background: #30ab51;
        color: #FFF;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
        padding: 1px 2px;
        margin-right: 20px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 998px) {
    .modal-body {
      background: transparent;

      .modal-detail {
        padding-left: 0px;
        margin-left: 0px;

        &:before {
          display: none;
        }
      }
    }
  }
}

.biz-info-wrapper {
  padding: 10px 30px;

  .limit-line {
    padding-top: 5px;

    span {
      display: inline-block;
      width: 150px;
    }
  }
}
